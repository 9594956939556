$(document).ready(function () {
  /* Init wow js animation */
  new WOW().init();

  /* Banner Slider using slick carousel */
  $('.banner-container').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    dots: false
  });

  /* Our Project Slider */
  $('.slider-project').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    centerMode: true,
    centerPadding: '12%',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '20%',
        }
      }
    ]
  })

  // $('.slick-project').on('init', function() {
  //   $('.slick-slide[data-slick-index="-1"]').addClass('lt1');
  //   $('.slick-slide[data-slick-index="1"]').addClass('rt1');
  // }).slick({
  //   infinite: true,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   dots: false,
  //   centerMode: true,
  //   centerPadding: '22rem',
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         centerPadding: '5rem',
  //       }
  //     }
  //   ]
  // }).on('beforeChange', (event, slick, current, next) => {
  //   $('.slick-slide.rt1').removeClass('rt1');
  //   $('.slick-slide.lt1').removeClass('lt1');

  //   const lt1 = (current < next && current > 0) ? current : next - 1;
  //   const rt1 = (current < next || next === 0) ? next + 1 : current;

  //   $(`.slick-slide[data-slick-index="${lt1}"]`).addClass('lt1');
  //   $(`.slick-slide[data-slick-index="${rt1}"]`).addClass('rt1');

  //   // Clone processing when moving from 2 to 0
  //   if (current === 2 && next === 0) {
  //     $(`.slick-slide[data-slick-index="${current}"]`).addClass('lt1');
  //     $(`.slick-slide[data-slick-index="${current + 2}"]`).addClass('rt1');
  //   }

  //   // Clone processing when moving from 0 to 2
  //   if (current === 0 && next === 2) {
  //     $(`.slick-slide[data-slick-index="${current}"]`).addClass('rt1');
  //     $(`.slick-slide[data-slick-index="${current - 2}"]`).addClass('lt1');
  //   }
  // });

  /* Media */
  $('.slider-media').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  /* Copyright year dynamic */
  $('.year').text('2023');
});

function isMobile() {
  return screen.width <= 768;
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

/* Click burger menu an show list menu */
$('.burger-menu').click(function () {
  var isShow = $('.menu-list-container').hasClass('show');

  $(this).toggleClass('open');
  $('#Header').toggleClass('show');
  $('.menu-list-container').toggleClass('show');

  if (isShow) {
    $('.menu-level-2').slideUp('800');
  }
})

/* run on mobile only, open submenu */
$('.menu-level-1 a').click(function () {
  var submenu = $(this).parent().find('.menu-level-2').length;
  if (submenu !== 0) {
    $(this).parent().find('.menu-level-2').toggleClass('show').slideToggle(500);
    $(this).find('.fa-regular').toggleClass('fa-chevron-down fa-chevron-up');
  }
})

$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  var scrollLength = isMobile() ? 600 : 800;

  if (scroll >= scrollLength) {
    $(".scroll-top").addClass("show");
    $('.header-menu').addClass('on-scroll');
  } else {
    $(".scroll-top").removeClass("show");
    $('.header-menu').removeClass('on-scroll');
  }
});